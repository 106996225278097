import React, { useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Col,
  Row,
  CardText,
  CardBody,
  CardTitle,
  FormGroup,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import { cpfCnpjMask } from 'js-essentials-functions'
import { useLocation } from 'react-router-dom'
import { useExcelDownloder } from 'react-xls'
import ReactDatetime from 'react-datetime'
import moment from 'moment'

import api from 'configs/api'
import Loading from 'components/Loading'
import { alertSuccess, alertDanger } from 'utils/toast'
import { reports, reportsGeneral, monthlyReport } from 'utils/reportsData'

const ReportsCompany = ({ company }: any) => {
  const { ExcelDownloder, Type } = useExcelDownloder()
  const params: any = useLocation()
  const companyId =
    params.pathname.split('/')[params.pathname.split('/').length - 1]
  const [loading, setLoading] = useState(false)
  const [dates, setDates] = useState({
    start: '',
    end: '',
  })

  const [download, setDownload] = useState<any>({
    id: 0,
    data: null,
  })

  // eslint-disable-next-line consistent-return
  const getReport = async (item: any) => {
    const { start, end } = dates

    if (!start && !end) {
      return alertDanger('Para acessar, preencha os períodos!')
    }
    setLoading(true)
    try {
      const { data } = await api.get(`${item.url}start=${start}&end=${end}`)
      if (data.length > 0) {
        setDownload({
          id: item.id,
          data: {
            Consultas: [...data],
          },
        })

        alertSuccess(
          'Sucesso!! Para fazer o download, clique no botão "baixar"'
        )
      } else if (data?.item?.length > 0) {
        setDownload({
          id: item.id,
          data: {
            Consultas: [...data?.item],
          },
        })
        alertSuccess(
          'Sucesso!! Para fazer o download, clique no botão "baixar"'
        )
      } else {
        alertDanger('Não foi encontrado nenhum dado')
      }
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
  }

  const getReportGeneral = async (item: any) => {
    setLoading(true)
    try {
      const { data } = await api.get(item.url)
      if (data?.item?.length > 0) {
        setDownload({
          id: item.id,
          data: {
            Consultas: [...data?.item],
          },
        })
        alertSuccess(
          'Sucesso!! Para fazer o download, clique no botão "baixar"'
        )
      } else if (data.length > 0) {
        setDownload({
          id: item.id,
          data: {
            dados: [...data],
          },
        })
        alertSuccess(
          'Sucesso!! Para fazer o download, clique no botão "baixar"'
        )
      } else {
        alertDanger('Não foi encontrado nenhum dado')
      }
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
  }

  // eslint-disable-next-line consistent-return
  const getMonthlyReport = async (item: any) => {
    const { start } = dates
    if (!start) {
      return alertDanger('Por favor, selecione uma data.')
    }
    setLoading(true)
    try {
      const { data } = await api.get(`${item.url}`)

      const headersExcluded = [
        'Beneficiário',
        'Categoria',
        'Data de cadastro',
        'Data da exclusão',
        'Valor por vida',
      ]
      const headers = [
        'Beneficiário',
        'Categoria',
        'Data de cadastro',
        'Valor por vida',
      ]

      const xlsxDataActive = []
      const xlsxDataExcluded = []
      const xlsxData = []

      company.national_registration
        ? xlsxDataActive.push([
            `Empresa: ${`${cpfCnpjMask(company?.national_registration)} - ${
              company?.legal_nature
            }`}`,
          ])
        : xlsxDataActive.push([`Empresa: ${company?.legal_nature}`])
      xlsxDataActive.push([``])

      // Beneficiários ativos
      xlsxDataActive.push(['Beneficiários cadastrados e ativos'])
      xlsxDataActive.push([''])
      xlsxDataActive.push(headers)

      // eslint-disable-next-line no-shadow
      data.activeUsers.users.forEach((item: any) => {
        xlsxDataActive.push([
          item.Beneficiário,
          item.Categoria,
          item['Data de cadastro'],
          item.Valor,
        ])
        if (item.Dependentes && item.Dependentes.length > 0) {
          item.Dependentes.forEach((dependent: any) => {
            xlsxDataActive.push([
              dependent.Beneficiário,
              dependent.Categoria,
              dependent['Data de cadastro'],
              dependent.Valor,
            ])
          })
        }
      })

      xlsxDataActive.push([''])
      xlsxDataActive.push([
        `Total de titulares ativos: ${data?.activeUsers?.holders}`,
      ])
      xlsxDataActive.push([
        `Total de dependentes ativos: ${data?.activeUsers?.dependents}`,
      ])
      xlsxDataActive.push([`Total ativos: ${data?.activeUsers?.total}`])

      // Beneficiários excluídos (dentro do período de exclusão)

      company.national_registration
        ? xlsxDataExcluded.push([
            `Empresa: ${`${cpfCnpjMask(company?.national_registration)} - ${
              company?.legal_nature
            }`}`,
          ])
        : xlsxDataExcluded.push([`Empresa: ${company?.legal_nature}`])
      xlsxDataExcluded.push([``])

      xlsxDataExcluded.push([
        'Beneficiários excluídos (dentro do período de exclusão)',
      ])
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push(headersExcluded)
      // eslint-disable-next-line no-shadow
      data.gracePeriodExclusions.users.forEach((item: any) => {
        xlsxDataExcluded.push([
          item.Beneficiário,
          item.Categoria,
          item['Data de cadastro'],
          item['Data de exclusão'],
          item.Valor,
        ])
        if (item.Dependentes && item.Dependentes.length > 0) {
          item.Dependentes.forEach((dependent: any) => {
            xlsxDataExcluded.push([
              dependent.Beneficiário,
              dependent.Categoria,
              dependent['Data de cadastro'],
              item['Data de exclusão'],
              dependent.Valor,
            ])
          })
        }
      })
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push([
        `Total de titulares excluídos: ${data.gracePeriodExclusions.holders}`,
      ])
      xlsxDataExcluded.push([
        `Total de dependentes excluídos: ${data.gracePeriodExclusions.dependents}`,
      ])
      xlsxDataExcluded.push([
        `Valor total: ${data.gracePeriodExclusions.total}`,
      ])
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push([
        'Os usuários excluídos dentro do período de exclusão, do dia 1º ao dia 5 de cada mês não deverão ser efetuadas cobranças',
      ])
      xlsxDataExcluded.push([``])

      // Beneficiários excluídos (fora do período de exclusão)

      xlsxDataExcluded.push([
        'Beneficiários excluídos (fora do período de exclusão)',
      ])
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push(headersExcluded)
      // eslint-disable-next-line no-shadow
      data.excluded.users.forEach((item: any) => {
        xlsxDataExcluded.push([
          item.Beneficiário,
          item.Categoria,
          item['Data de cadastro'],
          item['Data da exclusão'],
          item.Valor,
        ])
        if (item.Dependentes && item.Dependentes.length > 0) {
          item.Dependentes.forEach((dependent: any) => {
            xlsxDataExcluded.push([
              dependent.Beneficiário,
              dependent.Categoria,
              dependent['Data de cadastro'],
              item['Data da exclusão'],
              dependent.Valor,
            ])
          })
        }
      })
      xlsxDataExcluded.push([''])
      xlsxDataExcluded.push([
        `Total de titulares excluídos: ${data.excluded.holders}`,
      ])
      xlsxDataExcluded.push([
        `Total de dependentes excluídos: ${data.excluded.dependents}`,
      ])
      xlsxDataExcluded.push([`Valor total: ${data.excluded.total}`])

      // Extrato Geral

      company.national_registration
        ? xlsxData.push([
            `Empresa: ${`${cpfCnpjMask(company?.national_registration)} - ${
              company?.legal_nature
            }`}`,
          ])
        : xlsxData.push([`Empresa: ${company?.legal_nature}`])
      xlsxData.push([``])

      xlsxData.push(['Extrato Geral'])
      xlsxData.push([''])
      xlsxData.push([
        `Total de titulares ativos: ${data.overallSummary.activeHoldersTotal}`,
      ])
      xlsxData.push([
        `Total de dependetes ativos: ${data.overallSummary.activeDependentsTotal}`,
      ])
      xlsxData.push([
        `Total de vidas ativas: ${data.overallSummary.activeUsersTotal}`,
      ])
      xlsxData.push([`Total mínimo de vidas: ${data.overallSummary.minLives}`])
      xlsxData.push([
        `Titulares excluídos fora do período de carência: ${data.overallSummary.excludedHoldersTotal}`,
      ])
      xlsxData.push([
        `Dependentes excluídos fora do período de carência: ${data.overallSummary.excludedDependentsTotal}`,
      ])
      xlsxData.push([
        `Total de excluídos fora do período de carência: ${data.overallSummary.excludedUsersTotal}`,
      ])
      xlsxData.push([
        `Total de Vidas: ${data.overallSummary.ActiveAndExcludedUsersTotal}`,
      ])
      xlsxData.push([
        `Valor Total de titulares: ${data.overallSummary.activeUsersValue}`,
      ])
      xlsxData.push([
        `Valor Total de dependentes: ${data.overallSummary.activeDependentsValue}`,
      ])
      xlsxData.push([
        `Valor Total de excluídos fora do período de carência: ${data.overallSummary.excludedUsersValue}`,
      ])
      xlsxData.push([`Valor Total (R$): ${data.overallSummary.totalValue}`])
      xlsxData.push([''])
      xlsxData.push([
        'Se em seu contrato houver um Total mínimo de vidas e este for maior que o Total de vidas, o cálculo do Valor Total R$ utilizará o Total mínimo de vidas',
      ])

      setDownload({
        id: item.id,
        data: {
          'Ativos do mês': xlsxDataActive,
          'Excluidos do mês': xlsxDataExcluded,
          'Extrato Geral': xlsxData,
        },
      })

      alertSuccess('Sucesso!! Para fazer o download, clique no botão "baixar"')
    } catch (error) {
      alertDanger('Ocorreu algum erro')
    }
    setLoading(false)
  }

  const formattedDate = moment(dates.start, 'DD/MM/YYYY').format('YYYY-MM')

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}
      <CardHeader
        className="bg-transparent border-0"
        style={{ minHeight: 100 }}
      >
        <Row className="mt-3 align-items-center d-flex justify-content-between">
          <Col sm="auto" className="mb-2">
            <h3 className="mb-0 text-black">{`${company?.legal_nature} | Relatórios`}</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="align-items-center">
          <Col sm="auto">
            <strong>Período:</strong>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <h6 className="mb-0 text-black">Data inicial</h6>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione a data inicial',
                  }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={(e: any) =>
                    setDates({
                      ...dates,
                      start: moment(e).format('DD/MM/YYYY'),
                    })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <h6 className="mb-0 text-black">Data final</h6>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione a data final',
                  }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  onChange={(e: any) =>
                    setDates({ ...dates, end: moment(e).format('DD/MM/YYYY') })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {reports(companyId)?.map((report) => (
            <Col sm={3} className="mb-3" key={report.title}>
              <Card>
                <CardBody style={{ minHeight: 113 }}>
                  <CardTitle className="mb-1 text-sm">{report.title}</CardTitle>
                  <CardText className="text-xs">{report.description}</CardText>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      {download.id === report.id && download.data && (
                        <ExcelDownloder
                          data={download.data}
                          filename={`${report.filename}${dates.start}`}
                          type={Type.Button}
                        >
                          <Button
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                              setDownload({
                                id: 0,
                                data: null,
                              })
                            }}
                          >
                            <i className="fas fa-download mr-2" />
                            Baixar
                          </Button>
                        </ExcelDownloder>
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button size="sm" onClick={() => getReport(report)}>
                        Acessar Relatório
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <hr />
        <Row className="align-items-center">
          <Col sm="auto">
            <strong>Financeiro:</strong>
          </Col>

          <Col sm={4}>
            <FormGroup>
              <h6 className="mb-0 text-black">Período</h6>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: 'Selecione o mês e ano',
                  }}
                  dateFormat="MM/YYYY"
                  timeFormat={false}
                  onChange={(e: any) =>
                    setDates({
                      ...dates,
                      start: moment(e).format('DD/MM/YYYY'),
                    })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-2">
          {monthlyReport(companyId, formattedDate)?.map((report) => (
            <Col sm={3} className="mb-3" key={report.title}>
              <Card>
                <CardBody style={{ minHeight: 113 }}>
                  <CardTitle className="mb-1 text-sm">{report.title}</CardTitle>
                  <CardText className="text-xs">{report.description}</CardText>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      {download.id === report.id && download.data && (
                        <ExcelDownloder
                          data={download.data}
                          filename={`${report.filename}`}
                          type={Type.Button}
                        >
                          <Button
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                              setDownload({
                                id: 0,
                                data: null,
                              })
                            }}
                          >
                            <i className="fas fa-download mr-2" />
                            Baixar
                          </Button>
                        </ExcelDownloder>
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        size="sm"
                        onClick={() => getMonthlyReport(report)}
                      >
                        Acessar Relatório
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <hr />
        <Row className="align-items-center">
          <Col sm="auto">
            <strong>Gerais:</strong>
          </Col>
        </Row>
        <Row className="mt-2">
          {reportsGeneral(companyId)?.map((report) => (
            <Col sm={3} className="mb-3" key={report.title}>
              <Card>
                <CardBody style={{ minHeight: 113 }}>
                  <CardTitle className="mb-1 text-sm">{report.title}</CardTitle>
                  <CardText className="text-xs">{report.description}</CardText>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      {download.id === report.id && download.data && (
                        <ExcelDownloder
                          data={download.data}
                          filename={`${report.filename}`}
                          type={Type.Button}
                        >
                          <Button
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                              setDownload({
                                id: 0,
                                data: null,
                              })
                            }}
                          >
                            <i className="fas fa-download mr-2" />
                            Baixar
                          </Button>
                        </ExcelDownloder>
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        size="sm"
                        onClick={() => getReportGeneral(report)}
                      >
                        Acessar Relatório
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </CardBody>
    </div>
  )
}

export default ReportsCompany
