export const reports = (id: string) => [
  {
    id: 1,
    title: 'Consulta realizadas por colaboradores',
    description:
      'Retorna as consultas realizadas pelos colaboradores por período',
    url: `/manager/company/report/${id}?holder=1&`,
    filename: `Relatório-Colaboradores-`,
  },
  {
    id: 2,
    title: 'Consultas realizadas por dependentes',
    description:
      'Retorna as consultas realizadas pelos dependentes por período',
    url: `/manager/company/report/${id}?holder=0&`,
    filename: `Relatório-Dependentes-`,
  },
  {
    id: 3,
    title: 'Consultas realizadas por colaboradores e dependentes',
    description:
      'Retorna as consultas realizadas pelos dependentes e colaboradores por período',
    url: `/manager/company/report/${id}?holder=all&`,
    filename: `Relatório-Colaboradores-Dependente-`,
  },
  {
    id: 4,
    title: 'Colaboradores removidos',
    description: 'Retorna os colaboradores removidos por período',
    url: `/manager/company/${id}/users/deleted?`,
    filename: `Relatório-Colaboradores-Removidos-`,
  },
  {
    id: 5,
    title: 'Quantidade e especialidades realizadas',
    description: 'Retorna a quantidade e especialidades realizadas por período',
    url: `/manager/company/report/specialties/${id}?`,
    filename: `Relatório-Especialidades-`,
  },
]

export const reportsGeneral = (id: string) => [
  {
    id: 10,
    title: 'Todos colaboradores',
    description: 'Retorna todos os colaboradores e dependentes da empresa',
    url: `/manager/company/${id}/users?type=all&status_company=active&status_plan=active`,
    filename: `Relatório-TodosColaboradores-`,
  },
  {
    id: 11,
    title: 'Todos titulares',
    description: 'Retorna todos os colaboradores titulares da empresa',
    url: `/manager/company/${id}/users?type=1&status_company=active&status_plan=active`,
    filename: `Relatório-Titulares-`,
  },
  {
    id: 12,
    title: 'Todos dependentes',
    description: 'Retorna todos os dependentes da empresa',
    url: `/manager/company/${id}/users?type=0&status_company=active&status_plan=active`,
    filename: `Relatório-Dependentes-`,
  },
  {
    id: 13,
    title: 'Todos colaboradores ativos dos ultimos 12 meses',
    description: 'Retorna todos os colaboradores ativos dos ultimos 12 meses',
    url: `/manager/company/${id}/report-last-year`,
    filename: `Relatório-ativos-12-meses-`,
  },
]

export const monthlyReport = (id: string, date: any) => [
  {
    id: 20,
    title: 'Lista de titulares e dependentes',
    description: 'Retorna todos os titulares e dependentes e seus valores',
    url: `manager/company/${id}/report/finacial-general?month=${date}`,
    filename: `Relatório-titulares-e-dependentes-com-valores`,
  },
]
